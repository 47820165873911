import { ReactChildren } from 'react'
import styled from 'styled-components'
import { device } from 'src/device'
import logoLandscape from './logo-landscape.svg'
import logoPortrait from './logo-portrait.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ImageWrapperDesktop = styled.div`
  height: 60px;

  @media ${device.mobile} {
    display: none;
  }
`

const ImageWrapperMobile = styled.div`
  height: 30px;
  margin-right: 10px;
  display: none;

  @media ${device.mobile} {
    display: block;
  }
`

const Image = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
`

export function Logo({ children, ...props }: { children?: ReactChildren }) {
  return (
    <Container {...props}>
      <ImageWrapperDesktop>
        <Image src={logoPortrait} alt="Avica" />
      </ImageWrapperDesktop>
      <ImageWrapperMobile>
        <Image src={logoLandscape} alt="Avica" />
      </ImageWrapperMobile>
    </Container>
  )
}
