import { PropsWithChildren, useSyncExternalStore } from 'react'
import { createPortal } from 'react-dom'

export function createSlotContent(
  subscribe: (onStoreChange: () => void) => () => void,
  getSnapshot: () => Element | null
) {
  return function SlotContent({ children }: PropsWithChildren) {
    const element = useSyncExternalStore(subscribe, getSnapshot)

    if (!element) {
      return null
    }

    return createPortal(children, element)
  }
}
