import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { usePrevious } from 'react-use'

const BACKSPACE = 8

function useInput(
  value: string,
  setValue: (value: string) => void,
  onBack?: () => void,
  onDone?: () => void
) {
  const ref = useRef<HTMLInputElement>(null)

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.keyCode === BACKSPACE && value.length === 0 && onBack) {
        onBack()
      }
    },
    [onBack, value]
  )

  useEffect(() => {
    if (value.length === 3 && onDone) {
      onDone()
    }
  }, [onDone, value])

  const focus = useCallback(() => {
    ref.current?.focus()
  }, [ref])

  return {
    ref,
    value,
    onChange,
    onKeyDown,
    focus,
  }
}

export function useCode() {
  const [code, setCode] = useState('')

  const setThirdValue = useCallback(
    (thirdValue: string) => {
      setCode((code) => code.slice(0, 6) + thirdValue)
    },
    [setCode]
  )

  const setSecondValue = useCallback(
    (secondValue: string) => {
      setCode((code) => code.slice(0, 3) + secondValue + code.slice(6, 9))
    },
    [setCode]
  )

  const setFirstValue = useCallback(
    (firstValue: string) => {
      setCode((code) => firstValue + code.slice(3, 9))
    },
    [setCode]
  )

  const onBack = useCallback(() => {
    setCode((code) => code.slice(0, -1))
  }, [setCode])

  const thirdProps = useInput(code.slice(6, 9), setThirdValue, onBack)
  const secondProps = useInput(code.slice(3, 6), setSecondValue, onBack)
  const firstProps = useInput(code.slice(0, 3), setFirstValue, onBack)

  const focusFirst = firstProps.focus
  const focusSecond = secondProps.focus
  const focusThird = thirdProps.focus

  const previousCode = usePrevious(code)

  useEffect(() => {
    if (code.length === 6 && previousCode?.length === 5) {
      focusThird()
    } else if (code.length === 5 && previousCode?.length === 6) {
      focusSecond()
    }
    if (code.length === 3 && previousCode?.length === 2) {
      focusSecond()
    } else if (code.length === 2 && previousCode?.length === 3) {
      focusFirst()
    }
  }, [code, previousCode, focusFirst, focusSecond, focusThird])

  return {
    code,
    firstProps,
    secondProps,
    thirdProps,
  }
}
