import { graphql, http, HttpResponse } from 'msw'
import { v4 } from 'uuid'
import { cognito } from 'src/auth/mock/cognito'
import { addMedia, getAllMedia } from 'src/media/mockStore'

interface RequestBody {
  testKey: string
  key: string
}

export const handlers = [
  graphql.query('getScreensAndSlideshows', () => {
    return HttpResponse.json({
      data: {
        screens: [],
      },
    })
  }),
  graphql.query('getSlideshowsQuery', () => {
    return HttpResponse.json({
      data: {
        slideshows: [],
      },
    })
  }),
  graphql.query('getAllMedia', () => {
    return HttpResponse.json({
      data: getAllMedia(),
    })
  }),
  graphql.query('Organizations', () => {
    return HttpResponse.json({
      data: {
        organizations: [],
      },
    })
  }),
  graphql.query('uploadMediaRequest', () => {
    const id = v4()
    return HttpResponse.json({
      data: {
        uploadMediaRequest: {
          key: id,
          url: 'https://dummyUploadServer/upload',
          fields: [
            { key: 'testKey', value: 'testValue' },
            { key: 'key', value: id },
          ],
        },
      },
    })
  }),
  http.post<{}, RequestBody>(
    'https://dummyUploadServer/upload',
    async ({ request }) => {
      const body = await request.json()
      if (body.testKey === 'testValue') {
        setTimeout(() => {
          window.websocketMock.onMediaChanged(addMedia(body.key))
        }, 1000)
        return HttpResponse.json({})
      }
    }
  ),
  http.post('https://cognito-idp.eu-central-1.amazonaws.com', cognito),
]
