import { useTranslation } from 'react-i18next'
import { Box } from 'src/elements/Box'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { Slider } from 'src/elements/form/Slider'
import { gql } from 'src/graphql'
import { withFragments, withUndefined } from 'src/libraries/graphql-fragments'
import { usePlaylistSettings } from './usePlaylistSettings'

export const Settings = withFragments()(
  {
    playlist: withUndefined(
      gql(/* GraphQL */ `
        fragment Settings on Slideshow {
          id
          name
          slideDurationMs
        }
      `)
    ),
  },
  function ({ playlist }) {
    const { t } = useTranslation()
    const { name: initialName, slideDurationMs: initialSlideDurationMs } =
      playlist !== undefined
        ? playlist
        : { name: undefined, slideDurationMs: undefined }

    const { name, slideDurationMs } = usePlaylistSettings(playlist?.id, {
      name: initialName,
      slideDurationMs: initialSlideDurationMs,
    })

    return (
      <Box>
        <FormGroup>
          <Label loading={name.saving}>
            {t('playlists.edit.settings.name.label')}
          </Label>
          <Input
            name="name"
            value={name.value}
            onChange={name.onChange}
            disabled={initialName === undefined}
          />
        </FormGroup>
        <FormGroup noMargin>
          <Label loading={slideDurationMs.saving}>
            {t('playlists.edit.settings.slideDuration.label')}
          </Label>
          <Slider
            value={slideDurationMs.value}
            onChange={slideDurationMs.onChange}
            values={[5000, 10000, 15000, 20000, 25000, 30000, 45000, 60000]}
            labels={{
              5000: '5',
              10000: '10',
              15000: '15',
              20000: '20',
              25000: '25',
              30000: '30',
              45000: '45',
              60000: '60',
            }}
          />
        </FormGroup>
      </Box>
    )
  }
)
