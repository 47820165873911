import { ReactElement } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { device } from 'src/device'

interface ContainerProps {
  active: boolean
}

const Container = styled.div<ContainerProps>`
  font-size: 30px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;

  color: ${({ active, theme: { colors } }) =>
    active ? colors.primary : colors.blue};

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media ${device.mobile} {
    flex-direction: row;
    padding: 15px 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;

  @media ${device.mobile} {
    width: 80px;
  }
`

const ItemTitle = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 5px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 16px;
    margin-top: 0;
  }
`

export interface MenuItemProps {
  className?: string
  icon: IconProp
  name: string
  active?: boolean
  onClick?: () => void
}

export function MenuItem({
  className,
  icon,
  name,
  active = false,
  onClick,
}: MenuItemProps): ReactElement {
  return (
    <Container
      active={active}
      onClick={onClick}
      role="button"
      className={className}
    >
      <IconWrapper>
        <FontAwesomeIcon icon={icon} />
      </IconWrapper>
      <ItemTitle>{name}</ItemTitle>
    </Container>
  )
}
