import { ChangeEventHandler, useCallback } from 'react'
import { useDebouncedValue } from './useDebouncedValue'

export function useDebouncedInput(
  initialValue: string | undefined,
  onChange: (value: string) => Promise<void> | void
): [string | undefined, ChangeEventHandler<HTMLInputElement>, boolean] {
  const [value, onValueChange, saving] = useDebouncedValue(
    initialValue,
    onChange
  )

  const onValueChangeEvent = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onValueChange(e.target.value)
    },
    [onValueChange]
  )

  return [value, onValueChangeEvent, saving]
}
