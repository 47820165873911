import './index.css'
import './i18n'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { Amplify } from 'aws-amplify'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { registerSW } from 'virtual:pwa-register'
import { App } from './App'
import { getConfig } from './config'
import { isMocking } from './isMocking'
import { initalizeMock } from './mocks/browser'

async function init() {
  if (isMocking()) {
    const worker = initalizeMock()
    await worker.start({
      onUnhandledRequest: (req) => {
        console.log(req)
      },
    })
  } else {
    registerSW()
  }

  const config = await getConfig()

  if (config.gtmId) {
    const tagManagerArgs = {
      gtmId: config.gtmId,
      auth: config.gtmAuth,
      preview: config.gtmEnvironment,
    }

    TagManager.initialize(tagManagerArgs)
  }

  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    integrations: [new Integrations.BrowserTracing()],
    enabled: config.environment !== 'dev',
    tracesSampleRate: 1.0,
  })

  Amplify.configure({
    Auth: {
      region: config.region,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.adminUserPoolClientId,
    },
  })

  const container = document.getElementById('root')

  if (!container) {
    throw new Error('No container found')
  }

  const root = createRoot(container)

  root.render(<App />)
}

init()
