import styled from 'styled-components'

const Wrapper = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightBlue};
  border-radius: 2px;
`

const DoneProgress = styled(Wrapper)<ProgressProps>`
  background-color: ${(props) => props.theme.colors.blue};
  width: ${(props) => props.progress}%;
  transition: width 500ms ease-out;
`

export interface ProgressProps {
  progress: number
}

export interface ProgressBarProps {
  progress: number
}

export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <Wrapper>
      <DoneProgress progress={Math.max(Math.min(progress, 100), 0)} />
    </Wrapper>
  )
}
