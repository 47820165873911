import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Dashboard } from 'src/dashboard/Dashboard'
import { MediaLibraryScreen } from 'src/media/MediaLibraryScreen'
import { EnsureOnboarding } from 'src/onboarding/EnsureOnboarding'
import { Playlists } from 'src/playlists/Playlists'
import { EditPlaylist } from 'src/playlists/edit/EditPlaylist'
import { Screens } from 'src/screens/Screens'
import { useToggleOpener } from './hooks/useToggleOpener'
import { Layout } from './layout/Layout'
import { Navigation } from './layout/Navigation'
import { SideMenu } from './layout/SideMenu'
import { ScreenPage } from './screens/single/ScreenPage'

export function LoggedInRoutes() {
  const [showSidebar, toggleSidebar, closeSidebar] = useToggleOpener()

  const { pathname } = useLocation()
  useEffect(() => {
    closeSidebar()
  }, [pathname, closeSidebar])

  return (
    <EnsureOnboarding>
      <Layout
        sidebar={<SideMenu onCloseSidebar={closeSidebar} />}
        showSidebar={showSidebar}
        onHideSidebar={closeSidebar}
      >
        <Routes>
          <Route
            path="/"
            element={<Navigation onToggleSidebar={toggleSidebar} />}
          >
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="slideshows">
              <Route index element={<Playlists />} />
              <Route path=":id" element={<EditPlaylist />} />
              <Route path=":id/add" element={<EditPlaylist />} />
              <Route path=":id/edit" element={<EditPlaylist />} />
            </Route>
            <Route path="screens/:id" element={<ScreenPage />} />
            <Route path="screens" element={<Screens />}>
              <Route path="add" element={<Screens />} />
              <Route path="play" element={<Screens />} />
              <Route path="pair" element={<Screens />} />
            </Route>
            <Route path="media">
              <Route index element={<MediaLibraryScreen />} />
              <Route path=":id" element={<MediaLibraryScreen />} />
            </Route>
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Route>
        </Routes>
      </Layout>
    </EnsureOnboarding>
  )
}
