import { useCallback } from 'react'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { HintText } from 'src/elements/HintText'
import { OutlineBox } from 'src/elements/OutlineBox'

const StyledButton = styled(Button)`
  margin-top: 25px;
  margin-bottom: 5px;
`

const StyledOutlineBox = styled(OutlineBox)`
  cursor: pointer;
`

export interface UploaderProps {
  onFilesSelected: (files: File[]) => Promise<void>
}

export function Uploader({ onFilesSelected, ...props }: UploaderProps) {
  const { t } = useTranslation()

  const onDrop = useCallback(
    async (files: File[]) => onFilesSelected(files),
    [onFilesSelected]
  )

  return (
    <Dropzone
      onDrop={onDrop}
      multiple
      accept={['image/jpg', 'image/png', 'image/webp', 'image/jpeg']}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <StyledOutlineBox
          {...getRootProps()}
          active={isDragActive}
          data-test="upload-zone"
          {...props}
        >
          <input {...getInputProps()} />
          <StyledButton icon={faPlusCircle} label={t('mediaLibrary.new')} />
          <HintText>{t('mediaLibrary.uploadDragAndDropHelp')}</HintText>
        </StyledOutlineBox>
      )}
    </Dropzone>
  )
}
