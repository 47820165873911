import { ReactNode, Fragment, forwardRef, ForwardedRef } from 'react'
import styled from 'styled-components'
import { ScrollBar } from 'src/components/ScrollBar'
import { device } from 'src/device'

interface WrapperProps {
  bigRadius?: boolean
  shiftShadow?: boolean
  shadow?: boolean
  onClick?: () => void
}

interface ContentProps {
  children?: ReactNode
  smallPadding?: boolean
  smallPaddingOnMobile?: boolean
}

export type BoxProps = React.HTMLAttributes<HTMLDivElement> &
  ContentProps &
  WrapperProps & {
    scrollable?: boolean
  }

const Wrapper = styled.div<BoxProps>`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => (props.bigRadius ? '15px' : '10px')};

  box-shadow: ${(props) =>
    props.shadow
      ? props.shiftShadow
        ? '0 20px 20px rgba(0, 0, 0, 40%)'
        : '0 0 30px rgba(0, 0, 0, 16%)'
      : 'none'};
  position: relative;
`

const Content = styled.div<ContentProps>`
  padding: ${(props) => (props.smallPadding ? '10px' : '25px')};
  height: 100%;

  @media ${device.tablet} {
    ${(props) => props.smallPaddingOnMobile && 'padding: 15px'};
  }
`

function InternalBox(
  {
    children,
    scrollable,
    smallPadding,
    smallPaddingOnMobile,
    ...props
  }: BoxProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const InnerWrapper = scrollable ? ScrollBar : Fragment
  return (
    <Wrapper ref={ref} {...props}>
      <InnerWrapper>
        <Content
          smallPadding={smallPadding}
          smallPaddingOnMobile={smallPaddingOnMobile}
        >
          {children}
        </Content>
      </InnerWrapper>
    </Wrapper>
  )
}

export const Box = forwardRef(InternalBox)
