import { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { faSave } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH3 } from 'src/elements/TitleH3'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { Select } from 'src/elements/form/Select'
import { gql } from 'src/graphql'

const Wrapper = styled.div`
  max-width: 400px;
`

const FormWrapper = styled.form`
  padding-top: 20px;
`

interface PlaylistMap {
  [key: string]: string
}

export interface Props {
  playlistId: string | null
  onPlaylistSelected: (playlistId: string | null) => void
  screenName: string
}

export function PlaylistSelector({
  onPlaylistSelected,
  playlistId: initialPlaylistId,
  screenName,
}: Props) {
  const { t } = useTranslation()
  const playlists: PlaylistMap = {}

  const slideshowsQuery = useQuery(
    gql(/* GraphQL */ `
      query getSlideshowsQuery {
        slideshows {
          id
          name
        }
      }
    `)
  )

  slideshowsQuery.data?.slideshows.forEach((slideshow) => {
    playlists[slideshow.id] = slideshow.name
  })

  const [playlistId, setPlaylistId] = useState<string | null>(initialPlaylistId)

  const save = useCallback(() => {
    onPlaylistSelected(playlistId)
  }, [onPlaylistSelected, playlistId])

  return (
    <Wrapper>
      <TitleH3>{t('screens.playlists.selector.title')}</TitleH3>
      <SubTitle>{t('screens.playlists.selector.description')}</SubTitle>
      <FormWrapper>
        <FormGroup>
          <Label>{t('screens.playlists.selector.screenLabel')}</Label>
          <Input value={screenName} disabled data-test="display-name-input" />
        </FormGroup>
        <FormGroup data-test="playlist-select-wrapper">
          <Label>{t('screens.playlists.selector.playlistLabel')}</Label>
          <Select
            placeholder={t('screens.playlists.selector.playlistPlaceholder')}
            emptyTitle={t('screens.playlists.selector.noPlaylistSelected')}
            value={playlistId}
            onChange={setPlaylistId}
            options={playlists}
          />
        </FormGroup>
        <Button
          fullWidth
          label={t('screens.playlists.selector.saveButton')}
          icon={faSave}
          onClick={save}
        />
      </FormWrapper>
    </Wrapper>
  )
}
