import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { HamburgerIcon } from 'src/elements/HamburgerIcon'
import { NavigationSlot } from './NavigationSlot'

const ContentHeader = styled.div`
  margin-bottom: 30px;
  height: 30px;
`

const StyledHamburgerIcon = styled(HamburgerIcon)`
  display: none;

  @media ${device.mobile} {
    display: block;
  }
`

interface Props {
  onToggleSidebar: () => void
}

export function Navigation({ onToggleSidebar }: Props) {
  return (
    <>
      <ContentHeader>
        <StyledHamburgerIcon onClick={onToggleSidebar} />
        <NavigationSlot />
      </ContentHeader>
      <Outlet />
    </>
  )
}
