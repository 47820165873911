import { faArrowSquareLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: ${({
    theme: {
      colors: { blue },
    },
  }) => blue};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: ${({
        theme: {
          colors: { primary },
        },
      }) => primary};
    }
  }
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 30px;
  margin-right: 15px;
`

const Title = styled.div`
  text-transform: uppercase;
`

export interface BackIconProps {
  path: string
  title: string
}

export function BackIcon({ path, title, ...props }: BackIconProps) {
  return (
    <div {...props}>
      <StyledLink role="button" to={path}>
        <Icon icon={faArrowSquareLeft} />
        <Title>{title}</Title>
      </StyledLink>
    </div>
  )
}
