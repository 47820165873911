import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

interface IconProps {
  validpassword: boolean
}

const StyledIcon = styled(FontAwesomeIcon)<IconProps>`
  margin-left: 5px;
  color: ${(props) => (props.validpassword ? 'green' : props.theme.colors.red)};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 18px;
`

interface TextProps {
  validpassword: boolean
}

const Text = styled.p<TextProps>`
  color: ${(props) => (props.validpassword ? 'green' : props.theme.colors.red)};
  margin: 3px;
  margin-left: 10px;
  font-size: 14px;
`
interface Props {
  property: boolean
  name: string
}

export function ValidationProperty({ property, name }: Props) {
  return (
    <Container>
      <Wrapper>
        <StyledIcon
          icon={property ? faCheck : faTimes}
          validpassword={property}
        />
      </Wrapper>
      <Text validpassword={property}>{name}</Text>
    </Container>
  )
}
