import { useCallback } from 'react'
import styled from 'styled-components'

interface ListOptionProps {
  isEmpty: boolean
}

const ListOption = styled.div<ListOptionProps>`
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  color: ${(props) => props.theme.colors.darkBlue};
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-style: ${(props) => (props.isEmpty ? 'italic' : 'normal')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (hover: hover) {
    &:hover {
      background-color: ${(props) => props.theme.colors.lightBlue};
    }
  }
`

interface ListOptionItemProps {
  value: string | null
  name: string
  onSelect: (value: string | null) => void
  isEmpty?: boolean
}

export function ListOptionItem({
  onSelect,
  value,
  name,
  isEmpty = false,
}: ListOptionItemProps) {
  const handleClick = useCallback(() => {
    onSelect(value)
  }, [value, onSelect])
  return (
    <ListOption onClick={handleClick} isEmpty={isEmpty}>
      {name}
    </ListOption>
  )
}
