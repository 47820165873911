import { PropsWithChildren } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'

const ScrollBarContainer = styled(PerfectScrollbar)`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  overflow-anchor: none;
  touch-action: auto;
  padding-right: 4px;

  .ps__rail-y {
    background-color: ${(props) => props.theme.colors.grayMedium};
    position: absolute;
    right: 0;
    display: none;
    width: 4px !important;
    opacity: 1 !important;
    border-radius: 2px;
    transition:
      background-color 0.2s linear,
      opacity 0.2s linear;
  }

  &.ps--active-y > .ps__rail-y {
    background-color: ${(props) => props.theme.colors.grayMedium};
  }

  .ps__thumb-y {
    background-color: ${(props) => props.theme.colors.blue} !important;
    position: absolute;
    right: 0 !important;
    width: 4px !important;
    border-radius: 2px;
    transition:
      background-color 0.2s linear,
      width 0.2s ease-in-out;
  }
`

export interface ScrollBarProps {}

export function ScrollBar({ children }: PropsWithChildren<ScrollBarProps>) {
  return <ScrollBarContainer>{children}</ScrollBarContainer>
}
