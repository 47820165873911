import styled from 'styled-components'

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.transparentDarkBlue};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export interface OverlayProps {
  children: JSX.Element
}

export function Overlay({ children, ...props }: OverlayProps) {
  return <Background {...props}>{children}</Background>
}
