import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from 'src/elements/Box'
import { FormGroup } from 'src/elements/form/FormGroup'
import { Input } from 'src/elements/form/Input'
import { Label } from 'src/elements/form/Label'
import { Select } from 'src/elements/form/Select'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { Section } from 'src/playlists/edit/Section'

const StyledSection = styled(Section)`
  margin-bottom: 60px;
`

export const Details = withFragments()(
  {
    screen: gql(/* GraphQL */ `
      fragment ScreenDetailsFragment on Screen {
        id
        name
        slideshow {
          id
          name
        }
      }
    `),
  },
  function Details({ screen }) {
    const { t } = useTranslation()

    const loading = false

    const playlistQuery = useQuery(
      gql(/* GraphQL */ `
        query getSlideshowsQuery {
          slideshows {
            id
            name
          }
        }
      `)
    )

    const playlists = Object.fromEntries(
      playlistQuery.data?.slideshows.map((slideshow) => [
        slideshow.id,
        slideshow.name,
      ]) ?? []
    )

    return (
      <>
        <StyledSection
          title={t('screens.edit.settings.title')}
          data-test="settings"
        >
          <Box>
            <FormGroup>
              <Label loading={loading}>
                {t('playlists.edit.settings.name.label')}
              </Label>
              <Input name="name" value={screen.name} onChange={console.log} />
            </FormGroup>
            <FormGroup>
              <Label loading={loading}>
                {t('playlists.edit.settings.name.label')}
              </Label>
              <Select
                placeholder={t(
                  'screens.playlists.selector.playlistPlaceholder'
                )}
                emptyTitle={t('screens.playlists.selector.noPlaylistSelected')}
                value={screen.slideshow?.id ?? null}
                onChange={console.log}
                options={playlists}
              />
            </FormGroup>
          </Box>
        </StyledSection>
      </>
    )
  }
)
