import { useCallback } from 'react'
import { useToggle } from 'react-use'

type Opener = [boolean, () => void, () => void]

export function useToggleOpener(): Opener {
  const [isOpen, innerToggle] = useToggle(false)

  const toggle = useCallback(() => {
    innerToggle()
  }, [innerToggle])

  const close = useCallback(() => {
    innerToggle(false)
  }, [innerToggle])

  return [isOpen, toggle, close]
}
