import { MouseEventHandler } from 'react'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: ${({
    theme: {
      colors: { blue },
    },
  }) => blue};

  @media (hover: hover) {
    &:hover {
      color: ${({
        theme: {
          colors: { primary },
        },
      }) => primary};
    }
  }
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
`

export interface HamburgerIconProps {
  onClick: MouseEventHandler
}

export function HamburgerIcon({ onClick, ...props }: HamburgerIconProps) {
  return (
    <Wrapper role="button" onClick={onClick} {...props}>
      <Icon icon={faBars} />
    </Wrapper>
  )
}
