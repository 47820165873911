import { ReactElement, useCallback } from 'react'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { OutlineBox } from 'src/elements/OutlineBox'
import { useOpener } from 'src/hooks/useOpener'
import { MediaLibraryModal } from 'src/media/MediaLibraryModal'

const Box = styled(OutlineBox)`
  height: 140px;
  cursor: pointer;
`

export interface NewPlaylistProps {
  onAddMedia: (mediaId: string) => void
}

export function NewPlaylist({ onAddMedia }: NewPlaylistProps): ReactElement {
  const [isModalOpened, openModal, closeModal] = useOpener('add')
  const { t } = useTranslation()

  const selectFile = useCallback(
    (mediaId: string) => {
      closeModal()
      onAddMedia(mediaId)
    },
    [closeModal, onAddMedia]
  )

  return (
    <>
      <Box onClick={openModal} data-test="new-item">
        <Button
          icon={faPlusCircle}
          label={t('playlists.edit.items.newButtonTitle')}
        />
      </Box>
      <MediaLibraryModal
        show={isModalOpened}
        onFileSelected={selectFile}
        onClose={closeModal}
      />
    </>
  )
}
