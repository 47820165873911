import styled from 'styled-components'
import { AnimatingModal } from 'src/elements/AnimatingModal'
import { Media } from 'src/graphql/graphql'
import { FilePreview } from './FilePreview'

const StyledAnimatingModal = styled(AnimatingModal)`
  display: flex;
`

interface Props {
  file: (Partial<Pick<Media, 'url'>> & Pick<Media, 'id'>) | null
  onClose?: () => void
}

export function FilePreviewModal({ file, onClose }: Props) {
  return (
    <StyledAnimatingModal
      show={file !== null}
      onClose={onClose}
      animatingKey={file ? `file-${file.id}` : 'none'}
      autoSize
    >
      {file && <FilePreview file={file} onClose={onClose} />}
    </StyledAnimatingModal>
  )
}
