const { host, protocol } = window.location

const baseURL = host.split('.').slice(1).join('.')

function getServiceUrl(service: string) {
  return `${protocol}//${service}.${baseURL}`
}

export function getApiUrl() {
  return `${getServiceUrl('api')}/graphql`
}
