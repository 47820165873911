import styled from 'styled-components'

export const TitleH2 = styled.h2`
  line-height: 30px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
`
