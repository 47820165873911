import { useContext } from 'react'
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeContext,
} from 'styled-components'
import { device } from './device'

interface GlobalStyleProps {
  theme: DefaultTheme
  disableBackground: boolean
}

const GlobalStyleComponent = createGlobalStyle<GlobalStyleProps>`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    overflow-x: hidden;
    background-color: ${({ disableBackground }) =>
      disableBackground ? 'transparent !important' : 'inherit'};

    &.disable-select {
      user-select: none;
    }

    @media ${device.mobile} {
      background-color: ${({ theme: { colors }, disableBackground }) =>
        disableBackground ? 'transparent !important' : colors.lightBlue};
    }
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  input,
  select,
  button,
  textarea {
    appearance: none;
    font-family: 'Open Sans', sans-serif;
  }

  button:focus {
    outline: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  #root {
    height: 100%;
  }
`

export function GlobalStyleHelper({ disableBackground = false }) {
  const theme = useContext(ThemeContext)

  return (
    <GlobalStyleComponent theme={theme} disableBackground={disableBackground} />
  )
}
