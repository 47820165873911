import styled from 'styled-components'
import { Logo } from 'src/components/logo/Logo'

const Content = styled.div`
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLogo = styled(Logo)`
  margin-bottom: 40px;
`

export interface CenteredContentProps {
  children: React.ReactNode
  withLogo?: boolean
}

export function CenteredContent({
  children,
  withLogo = true,
}: CenteredContentProps) {
  return (
    <Content>
      <Wrapper>
        {withLogo && <StyledLogo />}
        {children}
      </Wrapper>
    </Content>
  )
}
