import { AnimatingModal } from 'src/elements/AnimatingModal'
import { Pairing } from './Pairing'

export interface PairingModalProps {
  onClose?: () => void
  show: boolean
  screenName: string
  onPair: (code: string) => void
}

export function PairingModal({
  show,
  onClose,
  screenName,
  onPair,
}: PairingModalProps) {
  return (
    <AnimatingModal showCloseButton onClose={onClose} show={show}>
      <Pairing screenName={screenName} onPair={onPair} />
    </AnimatingModal>
  )
}
