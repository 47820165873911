import { useEffect, useState } from 'react'

export function useShowProgressPopup(
  progress: number | null,
  fileCount: number
) {
  const [show, setShow] = useState(false)

  const [lastFileCount, setLastFileCount] = useState(0)

  useEffect(() => {
    if (progress === null) {
      setShow(false)
      return
    }

    if (progress >= 100 && lastFileCount === fileCount) {
      const timeout = setTimeout(() => {
        setShow(false)
      }, 3000)

      return () => {
        clearTimeout(timeout)
      }
    } else {
      setShow(true)
    }
    setLastFileCount(fileCount)
  }, [progress, lastFileCount, fileCount, setShow])

  return show
}
