const { host, protocol } = window.location

const baseDomain = host.split('.').slice(1).join('.')

function getSubdomain(subdomain: string) {
  return `${protocol}//${subdomain}.${baseDomain}`
}

export function getUnleashURL() {
  return getSubdomain('unleash')
}
