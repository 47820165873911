import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { device } from 'src/device'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { Card } from './Card'
import mediaLibrary from './media-library.svg'
import playlists from './playlists.svg'
import screens from './screens.svg'

const Header = styled.div`
  margin-bottom: 60px;
`

const CardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const CardCol = styled.div`
  flex: 1;
  padding: 0 15px;
  margin-bottom: 30px;
  max-width: ${100 / 3}%;

  @media ${device.tablet} {
    max-width: 50%;
    flex-basis: 50%;
  }

  @media ${device.mobile} {
    max-width: 100%;
    flex-basis: 100%;
  }
`

const StyledCard = styled(Card)`
  height: 100%;
`

export function Dashboard() {
  useScrollToTop()

  const { t } = useTranslation()

  return (
    <>
      <Header>
        <TitleH2>{t('dashboard.title')}</TitleH2>
        <SubTitle>{t('dashboard.subtitle')}</SubTitle>
      </Header>
      <CardRow>
        <CardCol>
          <StyledCard
            image={mediaLibrary}
            title={t('dashboard.mediaLibrary.title')}
            description={t('dashboard.mediaLibrary.description')}
            buttonTitle={t('dashboard.mediaLibrary.button.title')}
            buttonLink={'/media'}
          />
        </CardCol>
        <CardCol>
          <StyledCard
            image={playlists}
            title={t('dashboard.playlists.title')}
            description={t('dashboard.playlists.description')}
            buttonTitle={t('dashboard.playlists.button.title')}
            buttonLink={'/slideshows'}
          />
        </CardCol>
        <CardCol>
          <StyledCard
            image={screens}
            title={t('dashboard.screens.title')}
            description={t('dashboard.screens.description')}
            buttonTitle={t('dashboard.screens.button.title')}
            buttonLink={'/screens'}
          />
        </CardCol>
      </CardRow>
    </>
  )
}
