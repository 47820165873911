import { ReactNode, useCallback, useMemo } from 'react'
import RcSlider from 'rc-slider'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0 4px;
`

const StyledSlider = styled(RcSlider)`
  position: relative;
  width: 100%;
  height: 50px;
  padding: 11px 0 31px;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    padding: 0 10px;
    height: 8px;
    background-color: ${(props) => props.theme.colors.grayMedium};
    border-radius: 4px;
    margin-left: 4px;
  }

  .rc-slider-track {
    position: absolute;
    height: 8px;
    background-color: ${(props) => props.theme.colors.darkBlue};
    border-radius: 4px;
    margin-left: -4px;
  }

  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 8px;
    background: transparent;
    pointer-events: none;
  }

  .rc-slider-dot {
    position: absolute;
    bottom: 2px;
    width: 4px;
    height: 4px;
    vertical-align: middle;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 50%;
    cursor: pointer;
  }

  .rc-slider-mark {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    font-size: 14px;
  }

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: ${(props) => props.theme.colors.blue};
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .rc-slider-handle {
    position: absolute;
    width: 30px;
    height: 30px;
    margin-top: -11px;
    background-color: ${(props) => props.theme.colors.white};
    border: 4px solid ${(props) => props.theme.colors.darkBlue};
    box-shadow: 0 2px 10px ${(props) => props.theme.colors.transparentDark};
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    touch-action: pan-x;
  }

  .rc-slider-handle:focus {
    outline: none;
  }

  .rc-slider-handle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
`

const HighlightedMark = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.colors.darkBlue};
`

export interface SliderProps<T extends string | number> {
  value: T
  onChange: (value: T) => void
  values: T[]
  labels: Record<T, string>
}

export function Slider<T extends string | number>({
  value,
  onChange,
  values,
  labels,
}: SliderProps<T>) {
  const handleChange = useCallback(
    (value: number | number[]) => {
      const index = Array.isArray(value) ? value[0] : value
      onChange(values[index])
    },
    [values, onChange]
  )

  const currentIndex = values.indexOf(value) === -1 ? 0 : values.indexOf(value)

  const updatedMarks = useMemo(() => {
    const marks: Record<number, ReactNode> = {}

    values.forEach((value, index) => {
      const label = labels[value]
      if (index === currentIndex) {
        marks[index] = <HighlightedMark>{label}</HighlightedMark>
      } else {
        marks[index] = <>{label}</>
      }
    })

    return marks
  }, [values, labels, currentIndex])

  return (
    <Wrapper>
      <StyledSlider
        value={currentIndex}
        onChange={handleChange}
        marks={updatedMarks}
        min={0}
        max={values.length - 1}
      />
    </Wrapper>
  )
}
