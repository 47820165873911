import { FunctionComponent } from 'react'
import { ResultOf, TypedDocumentNode } from '@graphql-typed-document-node/core'
import { FragmentType, getFragmentData } from 'src/graphql'

type HasUndefined<T> = Extract<T, undefined> extends never ? true : false

type MaskUndefined<T, V> =
  HasUndefined<T> extends true ? Exclude<V, undefined> : V

type ComplexResultOf<T> = MaskUndefined<
  T,
  Exclude<T, undefined> extends TypedDocumentNode[]
    ? undefined | ResultOf<Exclude<T, undefined>[number]>[]
    : Exclude<T, undefined> extends TypedDocumentNode
      ? ResultOf<Exclude<T, undefined>> | undefined
      : undefined
>

type ComplexFragmentType<T> = MaskUndefined<
  T,
  Exclude<T, undefined> extends TypedDocumentNode[]
    ? undefined | FragmentType<Exclude<T, undefined>[number]>[]
    : Exclude<T, undefined> extends TypedDocumentNode
      ? undefined | FragmentType<Exclude<T, undefined>>
      : undefined
>

export function withFragments<AdditionalProps extends {} = {}>() {
  return function <
    Inputs extends Record<
      string,
      TypedDocumentNode | TypedDocumentNode[] | undefined
    >,
    InputProp extends {
      [key in keyof Inputs]: ComplexFragmentType<Inputs[key]>
    },
    OutputProp extends {
      [key in keyof Inputs]: ComplexResultOf<Inputs[key]>
    },
  >(fragments: Inputs, C: FunctionComponent<OutputProp & AdditionalProps>) {
    function Component(props: InputProp & AdditionalProps) {
      let transformedProp: OutputProp = {} as OutputProp

      Object.entries(fragments).forEach((entry) => {
        const key = entry[0] as keyof Inputs
        type Fragment = Inputs[keyof Inputs] extends TypedDocumentNode[]
          ? Exclude<Inputs[keyof Inputs][number], undefined>
          : Inputs[keyof Inputs] extends TypedDocumentNode
            ? Exclude<Inputs[keyof Inputs], undefined>
            : never
        const fragment = entry[1] as Fragment

        const inputFragment = props[key] as FragmentType<Fragment>

        const fragmentData = getFragmentData(
          fragment,
          inputFragment
        ) as OutputProp[keyof Inputs]
        transformedProp[key] = fragmentData
      })

      return <C {...props} {...transformedProp} />
    }

    Component.fragments = fragments as {
      [key in keyof Inputs]: Exclude<
        Inputs[key],
        undefined
      > extends TypedDocumentNode[]
        ? Exclude<Inputs[key], undefined>[number]
        : Exclude<Inputs[key], undefined>
    }

    return Component
  }
}

export function withUndefined<T>(value: T): T | undefined {
  return value
}

export function withArray<T>(value: T): T[] {
  return value as T[]
}
