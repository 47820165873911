import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import { create } from 'zustand'

interface State {
  user: CognitoUser | null
  isLoggedIn: boolean | null
}

interface Actions {
  onLoggedIn: (user: CognitoUser) => void
  onLoggedOut: () => void
  logout: () => Promise<void>
  login: (
    username: string,
    password: string,
    onNewPasswordRequired: (
      setNewPassword: (password: string) => Promise<void>
    ) => void
  ) => Promise<void>
  newPassword: () => Promise<void>
  forgetPassword: (username: string) => Promise<void>
  resetPassword: (
    username: string,
    code: string,
    password: string
  ) => Promise<void>
}

enum ChallengeName {
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
}

export const useAuthStore = create<State & Actions>((set) => ({
  user: null,
  isLoggedIn: null,
  onLoggedIn: (user: CognitoUser) => set({ user, isLoggedIn: true }),
  onLoggedOut: () => set({ user: null, isLoggedIn: false }),
  logout: async () => {
    await Auth.signOut()
  },
  login: async (
    username: string,
    password: string,
    onNewPasswordRequired: (
      setNewPassword: (password: string) => Promise<void>
    ) => void
  ) => {
    const user = await Auth.signIn(username, password)

    if (user.challengeName === ChallengeName.NewPasswordRequired) {
      onNewPasswordRequired(async (password: string) => {
        await Auth.completeNewPassword(user, password, {})
      })
    }
  },
  newPassword: async () => {
    const session = Auth.currentSession()
    console.log(session)
  },
  forgetPassword: async (username: string) => {
    await Auth.forgotPassword(username)
  },
  resetPassword: async (username: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(username, code, password)
  },
}))

async function checkLoggedInUser() {
  try {
    const user = (await Auth.currentAuthenticatedUser()) as CognitoUser
    useAuthStore.getState().onLoggedIn(user)
  } catch (e) {
    useAuthStore.getState().onLoggedOut()
  }
}

Hub.listen('auth', async (data) => {
  switch (data.payload.event) {
    case 'signIn':
      await checkLoggedInUser()
      break
    case 'signOut':
      useAuthStore.getState().onLoggedOut()
      break
    case 'configured':
      await checkLoggedInUser()
      break
  }
})
