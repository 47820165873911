import { createSlotComponent } from './Slot'
import { createSlotContent } from './SlotContext'

export function createSlot() {
  let slotElement: HTMLDivElement | null = null
  const subscribers = new Set<() => void>()

  function updateSlotElement(element: HTMLDivElement | null) {
    slotElement = element

    subscribers.forEach((subscriber) => {
      subscriber()
    })
  }

  function subscribe(onStoreChange: () => void) {
    subscribers.add(onStoreChange)

    return () => {
      subscribers.delete(onStoreChange)
    }
  }

  function getSnapshot() {
    return slotElement
  }

  const Slot = createSlotComponent(updateSlotElement)
  const SlotContent = createSlotContent(subscribe, getSnapshot)

  return { Slot, SlotContent }
}
