import { AnimatingModal } from 'src/elements/AnimatingModal'
import { PlaylistSelector } from './PlaylistSelector'

export interface Props {
  onClose?: () => void
  show: boolean
  screenName: string
  onPlaylistSelected: (playlistId: string | null) => void
  playlistId: string | null
}

export function PlaylistSelectorModal({
  show,
  screenName,
  onClose,
  onPlaylistSelected,
  playlistId,
}: Props) {
  return (
    <AnimatingModal showCloseButton onClose={onClose} show={show}>
      <PlaylistSelector
        onPlaylistSelected={onPlaylistSelected}
        playlistId={playlistId}
        screenName={screenName}
      />
    </AnimatingModal>
  )
}
