import { ReactElement, useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { arrayMove } from '@dnd-kit/sortable'
import { v4 } from 'uuid'
import { gql } from 'src/graphql'
import { withFragments, withUndefined } from 'src/libraries/graphql-fragments'
import { NewPlaylist } from './NewPlaylist'
import { SortablePlaylist } from './SortablePlaylist'

export const Editor = withFragments()(
  {
    playlist: withUndefined(
      gql(/* GraphQL */ `
        fragment SlideshowForEditor on Slideshow {
          id
          slides {
            id
            media {
              id
            }
            ...SlidesForSortablePlaylist
          }
          slideDurationMs
        }
      `)
    ),
  },
  function Editor({ playlist }): ReactElement {
    const { id, slides } = playlist || {}

    const client = useApolloClient()

    const [editSlides, editSlidesResult] = useMutation(
      gql(/* GraphQL */ `
        mutation editSlides($id: ID!, $slides: [SlideInput!]!) {
          editSlides(id: $id, slides: $slides) {
            id
            slides {
              id
              media {
                id
                url
                thumbnailUrl
              }
            }
          }
        }
      `),
      {
        optimisticResponse: ({ id, slides }) => {
          return {
            editSlides: {
              id,
              slides: Array.isArray(slides)
                ? slides.map((slide) => ({
                    id: slide.id,
                    media: client.readFragment({
                      id: `Media:${slide.mediaId}`,
                      fragment: gql(/* GraphQL */ `
                        fragment _ on Media {
                          id
                          url
                          thumbnailUrl
                        }
                      `),
                    })!,
                  }))
                : [],
              __typename: 'Slideshow' as const,
            },
          }
        },
      }
    )

    const onSortEnd = (oldIndex: number, newIndex: number) => {
      if (oldIndex !== newIndex && slides && id !== undefined) {
        const slidesInput: any = slides.map((slide) => ({
          id: slide.id,
          mediaId: slide.media?.id ?? null,
        }))

        editSlides({
          variables: {
            id,
            slides: arrayMove(slidesInput, oldIndex, newIndex),
          },
        })
      }
    }

    const onChangeMedia = useCallback(
      (index: number, mediaId: string) => {
        if (slides && id !== undefined) {
          const slidesInput: any = slides.map((slide, slideIndex) => ({
            id: slide.id,
            mediaId: slideIndex === index ? mediaId : (slide.media?.id ?? null),
          }))

          editSlides({
            variables: {
              id,
              slides: slidesInput,
            },
          })
        }
      },
      [id, slides, editSlides]
    )

    const deleteItem = useCallback(
      (deleteIndex: number) => {
        if (slides && id !== undefined) {
          const slidesInput: any = slides
            .filter((_, index) => index !== deleteIndex)
            .map((slide) => ({
              id: slide.id,
              mediaId: slide.media?.id ?? null,
            }))

          editSlides({
            variables: {
              id,
              slides: slidesInput,
            },
          })
        }
      },
      [id, slides, editSlides]
    )

    const addItem = useCallback(
      (mediaId: string) => {
        if (slides && id !== undefined) {
          const slidesInput: any = [
            ...slides.map((slide) => ({
              id: slide.id,
              mediaId: slide.media?.id ?? null,
            })),
            {
              id: v4(),
              mediaId,
            },
          ]

          editSlides({
            variables: {
              id,
              slides: slidesInput,
            },
          })
        }
      },
      [id, slides, editSlides]
    )

    return (
      <div data-saving={editSlidesResult.loading}>
        {slides && (
          <SortablePlaylist
            slides={slides}
            onSortEnd={onSortEnd}
            onChangeMedia={onChangeMedia}
            onDelete={deleteItem}
          />
        )}
        <NewPlaylist onAddMedia={addItem} />
      </div>
    )
  }
)
