import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TitleH2 } from 'src/elements/TitleH2'

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export function Loader() {
  const { t } = useTranslation()

  return (
    <Loading>
      <TitleH2>{t('app.loading')}</TitleH2>
    </Loading>
  )
}
