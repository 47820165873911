import styled from 'styled-components'

export const SmallTitle = styled.h4`
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
`
