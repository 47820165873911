import { useCallback } from 'react'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { OutlineBox } from 'src/elements/OutlineBox'
import { useOpener } from 'src/hooks/useOpener'
import { AddScreenModal } from './AddScreenModal'

const StyledOutlineBox = styled(OutlineBox)`
  height: 100%;
`

export interface AddScreenProps {
  onAddScreen: (screenName: string) => void
}

export function AddScreen({ onAddScreen, ...props }: AddScreenProps) {
  const { t } = useTranslation()

  const [isAddScreenOpened, openAddScreen, closeAddScreen] = useOpener('add')

  const addScreen = useCallback(
    async (screenName: string) => {
      await onAddScreen(screenName)
      closeAddScreen()
    },
    [onAddScreen, closeAddScreen]
  )

  return (
    <>
      <StyledOutlineBox onClick={openAddScreen} {...props}>
        <Button label={t('screens.addScreen')} icon={faPlusCircle} />
      </StyledOutlineBox>
      <AddScreenModal
        onAddScreen={addScreen}
        onClose={closeAddScreen}
        show={isAddScreenOpened}
      />
    </>
  )
}
