import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { Box } from './Box'

const Container = styled.div`
  position: sticky;
  bottom: 45px;
  height: 0;
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: fit-content;
  pointer-events: none;
`

export interface PopupProps {
  children: JSX.Element
  show?: boolean
  onHide?: () => void
}

export function Popup({ children, show = true, onHide }: PopupProps) {
  return (
    <Container>
      <Wrapper>
        <AnimatePresence>
          {show && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: 'linear',
                duration: 0.25,
              }}
              onAnimationEnd={onHide}
            >
              <Box shadow shiftShadow>
                {children}
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    </Container>
  )
}
