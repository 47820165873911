import { useCallback } from 'react'
import { faRetweetAlt } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { useAuthStore } from '../useAuthStore'
import { AuthPage } from './AuthPage'
import { FormField } from './FormField'

export function ForgetPasswordScreen() {
  useScrollToTop()

  const { t } = useTranslation()
  const forgetPassword = useAuthStore(({ forgetPassword }) => forgetPassword)

  const navigate = useNavigate()

  const onSubmit = useCallback(
    async ({ username }: { username: string }) => {
      await forgetPassword(username)
      navigate('/reset-password', {
        state: {
          email: username,
        },
      })
    },
    [navigate, forgetPassword]
  )

  return (
    <AuthPage
      title={t('auth.forgetPassword.title')}
      error={t('auth.forgetPassword.failedSubmit')}
      description={t('auth.forgetPassword.description')}
      defaultField="username"
      onSubmit={onSubmit}
      submitButtonTitle={t('auth.forgetPassword.submit')}
      submitButtonIcon={faRetweetAlt}
      submitButtonTestId="forget-password-submit-button"
    >
      <FormField
        name="username"
        type="email"
        label={t('auth.forgetPassword.email.label')}
        requiredMessage={t('auth.forgetPassword.emailIsRequired')}
        data-test="username-input"
      />
    </AuthPage>
  )
}
