import { v4 } from 'uuid'
import { Media } from 'src/graphql/graphql'

let dynamicFiles: Media[] = []

export const getAllMedia = () => ({
  getAllMedia: dynamicFiles,
})
export const uploadMediaRequest = () => {
  const id = v4()
  return {
    uploadMediaRequest: {
      key: id,
      url: 'https://dummyUploadServer/upload',
      fields: [
        { key: 'testKey', value: 'testValue' },
        { key: 'key', value: id },
      ],
    },
  }
}

export const addMedia = (id: string) => {
  const pictureId = dynamicFiles.length + 10

  const newMedia = {
    id,
    url: `https://picsum.photos/seed/picsum${pictureId}/640/360`,
    thumbnailUrl: `https://picsum.photos/seed/picsum${pictureId}/320/180`,
    created: Date.now() / 1000,
  }

  dynamicFiles.push(newMedia)

  return newMedia
}

export const setMediaFiles = (mediaFiles: Media[]) => {
  dynamicFiles = JSON.parse(JSON.stringify(mediaFiles))
}

export function getMedia(mediaId: string) {
  return dynamicFiles.find((media) => media.id === mediaId)
}
