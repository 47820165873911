import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SubTitle } from 'src/elements/SubTitle'
import { Media } from 'src/graphql/graphql'
import { ThumbnailList } from './ThumbnailList'
import { Uploader } from './Uploader'
import { UploadStatusPopup } from './upload-status/UploadStatusPopup'
import { useMediaLibrary } from './useMediaLibrary'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ThumbnailListWrapper = styled.div`
  flex: 1;
`

const StyledUploader = styled(Uploader)`
  margin-bottom: 30px;
`

const StyledSubTitle = styled(SubTitle)`
  margin-top: 0;
  margin-bottom: 30px;
`

function sortMediaByDate(
  a: Pick<Media, 'id'> & Partial<Pick<Media, 'created'>>,
  b: Pick<Media, 'id'> & Partial<Pick<Media, 'created'>>
) {
  return a.created === undefined
    ? 1
    : b.created === undefined
      ? 1
      : b.created - a.created
}

interface Props {
  onFileSelected?: (fileId: string) => void
}

export function MediaLibrary({ onFileSelected }: Props) {
  const {
    isLoaded,
    uploadFiles,
    files,
    fileCount,
    finishedFileCount,
    progress,
    resetProgress,
  } = useMediaLibrary()

  const { t } = useTranslation()

  return (
    <Wrapper>
      <StyledSubTitle>
        {isLoaded ? (
          t('mediaLibrary.count', {
            count: files.length,
          })
        ) : (
          <>&nbsp;</>
        )}
      </StyledSubTitle>
      <StyledUploader onFilesSelected={uploadFiles} />
      <ThumbnailListWrapper>
        <ThumbnailList
          files={files.sort(sortMediaByDate)}
          onSelect={onFileSelected}
        />
      </ThumbnailListWrapper>
      <UploadStatusPopup
        fileCount={fileCount}
        finishedFileCount={finishedFileCount}
        progress={progress}
        onHide={resetProgress}
      />
    </Wrapper>
  )
}
