export const theme = {
  colors: {
    white: '#fff',
    lightBlue: '#EDF0F2',
    grayMedium: '#CED2D9',
    transparentBlue: 'rgba(165,165,184,0.2)',
    transparentDarkBlue: 'rgb(36,36,54, 0.75)',
    transparentDark: 'rgba(8, 8, 11, 0.5)',
    blue: '#7A8599',
    red: '#f75f5f',
    darkBlue: '#4b4b6f',
    primary: '#f7b346',
  },
  font: {
    primary: "'Open Sans', sans-serif",
    secondary: "'Exo', sans-serif",
  },
}

type AvicaTheme = typeof theme

declare module 'styled-components' {
  export interface DefaultTheme extends AvicaTheme {}
}
