import { AnimatingModal } from 'src/elements/AnimatingModal'
import { AddScreenForm } from './AddScreenForm'

export interface AddScreenModalProps {
  onClose?: () => void
  show: boolean
  onAddScreen: (name: string) => void
}

export function AddScreenModal({
  show,
  onClose,
  onAddScreen,
}: AddScreenModalProps) {
  return (
    <AnimatingModal showCloseButton onClose={onClose} show={show}>
      <AddScreenForm onAddScreen={onAddScreen} />
    </AnimatingModal>
  )
}
