import validator from 'validator'

export function useValidatePassword(string: string = '') {
  const lowerChar = validator.matches(string, /[a-z]/)
  const upperChar = validator.matches(string, /[A-Z]/)
  const number = validator.matches(string, /\d/)
  const length = string.length >= 8

  return {
    lowerChar,
    upperChar,
    number,
    length,
  }
}
