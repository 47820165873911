import FlagProvider from '@unleash/proxy-client-react'
import { ThemeProvider } from 'styled-components'
import { FeatureFlaggedApp } from './FeatureFlaggedApp'
import { GlobalStyleHelper as GlobalStyle } from './GlobalStyle'
import { useConfig } from './config/useConfig'
import { unleashClient } from './feature-flags/unleashClient'
import { theme } from './theme'

export function InnerApp() {
  const config = useConfig()

  return (
    config && (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <FlagProvider unleashClient={unleashClient}>
          <FeatureFlaggedApp config={config} />
        </FlagProvider>
      </ThemeProvider>
    )
  )
}
