import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'src/device'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { BackIcon } from 'src/layout/BackIcon'
import { NavigationSlotContent } from 'src/layout/NavigationSlot'
import { Section } from './Section'
import { Settings } from './Settings'
import { Editor } from './editor/Editor'
import { Preview } from './preview/Preview'

const Header = styled.div`
  margin-bottom: 60px;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  width: 75%;

  @media ${device.tablet} {
    width: 100%;
  }
`

const StyledSection = styled(Section)`
  margin-bottom: 60px;
`

const StyledBackIcon = styled(BackIcon)`
  display: block;

  @media ${device.mobile} {
    display: none;
  }
`

export function EditPlaylist() {
  useScrollToTop()

  const { t } = useTranslation()
  let { id } = useParams<'id'>()

  const playlistId = id ?? ''

  const playlistQuery = useQuery(
    gql(/* GraphQL */ `
      query getSlideshow($id: ID!) {
        slideshow(id: $id) {
          slides {
            ...Preview
          }
          slideDurationMs
          ...Settings
          ...SlideshowForEditor
        }
      }
    `),
    {
      variables: { id: playlistId },
    }
  )

  const playlist = playlistQuery.data?.slideshow

  return (
    <div>
      <NavigationSlotContent>
        <StyledBackIcon
          path="/slideshows"
          title={t('routes.slideshows.main')}
        />
      </NavigationSlotContent>
      <Header>
        <TitleH2>{t('playlists.edit.title')}</TitleH2>
      </Header>
      <ContentContainer>
        <ContentWrapper>
          <StyledSection
            title={t('playlists.edit.settings.title')}
            data-test="settings"
          >
            <Settings playlist={playlist} />
          </StyledSection>
          <StyledSection title={t('playlists.edit.preview.title')}>
            <Preview
              slides={playlist?.slides}
              slideDurationMs={playlist?.slideDurationMs}
            />
          </StyledSection>
          <StyledSection
            title={t('playlists.edit.items.title')}
            data-test="items-section"
          >
            <Editor playlist={playlist} />
          </StyledSection>
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}
