import {
  delay,
  graphql,
  ws,
  HttpResponse,
  WebSocketHandlerConnection,
  WebSocketHandler,
} from 'msw'
import { Media } from './graphql/graphql'

interface Subscription {
  connection: WebSocketHandlerConnection
  variables: any
  id: string
}

export function query<TData extends Record<string, any>>(
  operation: string,
  data: TData | ((variables: any) => TData),
  delayDuration?: number
) {
  return graphql.query(operation, async ({ variables }) => {
    const calculatedData: TData =
      data instanceof Function ? data(variables) : data
    await delay(delayDuration)
    return HttpResponse.json({
      data: calculatedData,
    })
  })
}

export function noResponse(operation: string) {
  return graphql.query(operation, async () => {
    await delay('infinite')

    return HttpResponse.json({})
  })
}

export function mutation<TData extends Record<string, any>>(
  operation: string,
  data: (variables: any) => TData,
  delayDuration?: number
) {
  return graphql.mutation(operation, async ({ variables }) => {
    const calculatedData = data(variables)

    await delay(delayDuration)

    return HttpResponse.json({
      data: calculatedData,
    })
  })
}

export interface MockServer {
  onMediaChanged: (media: Media) => void
  handler: WebSocketHandler
}

let mockServer: MockServer | null = null

function createMockServer(url: string) {
  const server = ws.link(url)

  const subscriptions: Subscription[] = []

  function onMediaChanged(media: Media) {
    subscriptions.forEach(({ id, variables, connection }) => {
      if (variables.mediaId !== media.id) {
        return
      }
      const message = {
        id,
        type: 'next',
        payload: {
          data: { onMediaChanged: media },
        },
      }
      connection.client.send(JSON.stringify(message))
    })
  }

  const handler = server.addEventListener('connection', (connection) => {
    connection.client.addEventListener('message', ({ data }) => {
      const message = JSON.parse(data.toString())
      if (message.type === 'connection_init') {
        connection.client.send(
          JSON.stringify({
            type: 'connection_ack',
          })
        )
      } else if (message.type === 'subscribe') {
        const { variables } = message.payload
        subscriptions.push({
          id: message.id,
          variables,
          connection,
        })
      } else {
        console.log(message)
      }
    })
  })

  return {
    onMediaChanged,
    handler,
  }
}

export function mockWebsocket(url: string): MockServer {
  if (mockServer === null) {
    mockServer = createMockServer(url)
  }

  return mockServer
}
