import styled from 'styled-components'

export const TitleH3 = styled.h3`
  line-height: 25px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
`
