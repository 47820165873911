import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 10px;
`

const StyledLink = styled(RouterLink)`
  color: ${(props) => props.theme.colors.primary};

  font-size: 12px;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

export interface LinkProps {
  to: string
  children: React.ReactNode
}

export function Link({ to, children }: LinkProps) {
  return (
    <Wrapper>
      <StyledLink to={to}>{children}</StyledLink>
    </Wrapper>
  )
}
