import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  centered?: boolean
  invalid?: boolean
}

export const Input = styled.input<InputProps>`
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid
    ${(props) =>
      props.invalid ? props.theme.colors.red : props.theme.colors.grayMedium};
  outline: none;
  color: ${(props) => props.theme.colors.darkBlue};
  font-size: 16px;
  font-weight: bold;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  width: 100%;

  ::placeholder {
    color: ${(props) => props.theme.colors.blue};
    font-weight: normal;
    font-style: italic;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.lightBlue};
    border-color: ${(props) => props.theme.colors.lightBlue};
    -webkit-text-fill-color: ${(props) => props.theme.colors.darkBlue};
  }
`
