import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgetPasswordScreen } from './auth/login/ForgetPasswordScreen'
import { LoginScreen } from './auth/login/LoginScreen'
import { ResetPasswordScreen } from './auth/login/ResetPasswordScreen'
import { Layout } from './layout/Layout'

export function UnauthenticatedRoutes() {
  return (
    <Layout>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/forget-password" element={<ForgetPasswordScreen />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Layout>
  )
}
