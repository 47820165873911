import { ReactElement } from 'react'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { OutlineBox } from 'src/elements/OutlineBox'

const StyledOutlineBox = styled(OutlineBox)`
  height: 100%;
`

export interface AddPlaylistProps {
  onAddPlaylist: () => void
}

export function AddPlaylist({
  onAddPlaylist,
  ...props
}: AddPlaylistProps): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <StyledOutlineBox onClick={onAddPlaylist} {...props}>
        <Button label={t('playlists.addPlaylist')} icon={faPlusCircle} />
      </StyledOutlineBox>
    </>
  )
}
