import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProgressBar } from 'src/components/ProgressBar'
import { SmallTitle } from 'src/elements/SmallTitle'
import { useLastValidValue } from 'src/hooks/useLastValidValue'

const OuterWrapper = styled.div`
  max-width: 400px;
`

const Wrapper = styled.div`
  max-width: 100%;
  width: 100vw;
`

const StyledSmallTitle = styled(SmallTitle)`
  margin-bottom: 20px;
`

const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: 5px;
`

export interface UploadStatusProps {
  fileCount: number
  finishedFileCount: number
  progress: number | null
}

export function UploadStatus({
  fileCount,
  finishedFileCount,
  progress,
}: UploadStatusProps) {
  const { t } = useTranslation()

  const lastProgress = useLastValidValue(progress, 0)

  return (
    <OuterWrapper>
      <Wrapper>
        <StyledSmallTitle>
          {t('mediaLibrary.uploadStatus.title')}
        </StyledSmallTitle>
        <StatusWrapper>
          <div>{lastProgress}%</div>
          <div>
            {t('mediaLibrary.uploadStatus.files')}: {finishedFileCount}/
            {fileCount}
          </div>
        </StatusWrapper>
        <ProgressBar progress={lastProgress} />
      </Wrapper>
    </OuterWrapper>
  )
}
