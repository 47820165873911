import { useEffect, useState } from 'react'

export function useLastValidValue(value: any, defaultValue: any) {
  const [lastValidValue, setLastValidValue] = useState(defaultValue)

  useEffect(() => {
    if (value !== null) {
      setLastValidValue(value)
    }
  }, [value])

  return lastValidValue
}
