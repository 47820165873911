import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { gql } from 'src/graphql'
import { useDebouncedInput } from 'src/hooks/useDebouncedInput'
import { useDebouncedValue } from 'src/hooks/useDebouncedValue'

const DEFAULT_SLIDE_DURATION_MS = 30000

export function usePlaylistSettings(
  id: string | undefined,
  initial: {
    name?: string
    slideDurationMs?: number
  }
) {
  const [renameSlideshowMutation] = useMutation(
    gql(/* GraphQL */ `
      mutation renameSlideshow($id: ID!, $name: String!) {
        renameSlideshow(id: $id, name: $name) {
          id
          name
        }
      }
    `)
  )

  const onNameChange = useCallback(
    async (name: string) => {
      if (id !== undefined) {
        await renameSlideshowMutation({
          variables: {
            id,
            name,
          },
        })
      }
    },
    [id, renameSlideshowMutation]
  )

  const [setSlideDurationMutation] = useMutation(
    gql(/* GraphQL */ `
      mutation setSlideDuration($id: ID!, $durationMs: Int!) {
        setSlideDuration(id: $id, durationMs: $durationMs) {
          id
          slideDurationMs
        }
      }
    `)
  )

  const onSlideDurationMs = useCallback(
    async (durationMs: number) => {
      if (id !== undefined) {
        await setSlideDurationMutation({
          variables: {
            id,
            durationMs,
          },
        })
      }
    },
    [id, setSlideDurationMutation]
  )

  const [name, onNameChangeEvent, nameSaving] = useDebouncedInput(
    initial.name,
    onNameChange
  )

  const [slideDurationMs, handleSlideDurationMsChange, slideDurationSaving] =
    useDebouncedValue(initial.slideDurationMs, onSlideDurationMs, 500)

  return {
    name: {
      value: name ?? '',
      onChange: onNameChangeEvent,
      saving: nameSaving,
    },
    slideDurationMs: {
      value: slideDurationMs ?? DEFAULT_SLIDE_DURATION_MS,
      onChange: handleSlideDurationMsChange,
      saving: slideDurationSaving,
    },
  }
}
