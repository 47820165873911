import { useCallback, useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import { PuffLoader } from 'react-spinners'
import styled, { ThemeContext } from 'styled-components'
import { Media } from 'src/graphql/graphql'

const Wrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  padding-top: 75%;
  position: relative;
`

const CoverImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`

export interface Props {
  file: Pick<Media, 'id'> & Partial<Pick<Media, 'thumbnailUrl'>>
  onSelect?: (id: string) => void
}

export function Thumbnail({ file: { id, thumbnailUrl }, onSelect }: Props) {
  const onSelectCallback = useCallback(() => {
    if (onSelect && id) {
      onSelect(id)
    }
  }, [onSelect, id])

  const theme = useContext(ThemeContext)

  const { ref, inView } = useInView({
    rootMargin: '200px 0px',
  })

  return (
    <Wrapper data-test="media-library-thumbnail" ref={ref}>
      {inView && thumbnailUrl !== undefined ? (
        <CoverImage
          src={thumbnailUrl}
          onClick={onSelectCallback}
          className={onSelect ? 'cursor-pointer' : ''}
        />
      ) : (
        <Loader>
          <PuffLoader color={theme.colors.blue} />
        </Loader>
      )}
    </Wrapper>
  )
}
