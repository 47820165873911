import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { Config } from './config'
import { useCreateApolloClient } from './hooks/useCreateApolloClient'
import { Page } from './layout/Page'

interface Props {
  config: Config
}

export function ConfiguredApp({ config }: Props) {
  const apolloClient = useCreateApolloClient(config)

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Page />
      </BrowserRouter>
    </ApolloProvider>
  )
}
