import { useCallback } from 'react'
import { faRetweetAlt } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useScrollToTop } from 'src/hooks/useScrollToTop'
import { useAuthStore } from '../useAuthStore'
import { AuthHistoryState } from './AuthHistoryState'
import { AuthPage } from './AuthPage'
import { FormField } from './FormField'
import { PasswordValidation } from './PasswordValidation'

export function ResetPasswordScreen() {
  useScrollToTop()

  const { t } = useTranslation()
  const resetPassword = useAuthStore(({ resetPassword }) => resetPassword)

  const navigate = useNavigate()

  const onSubmit = useCallback(
    async ({
      username,
      code,
      password,
    }: {
      username: string
      code: string
      password: string
    }) => {
      await resetPassword(username, code, password)
      navigate('/login', {
        state: {
          passwordChanged: true,
        },
      })
    },
    [navigate, resetPassword]
  )

  const location = useLocation()

  const state = location.state as AuthHistoryState | undefined

  return (
    <AuthPage
      title={t('auth.resetPassword.title')}
      error={t('auth.resetPassword.failedSubmit')}
      description={t('auth.resetPassword.description')}
      defaultField="code"
      defaultValues={{ username: state?.email ?? '' }}
      onSubmit={onSubmit}
      submitButtonTitle={t('auth.resetPassword.submit')}
      submitButtonIcon={faRetweetAlt}
      submitButtonTestId="reset-password-submit-button"
    >
      <FormField
        name="username"
        type="email"
        label={t('auth.resetPassword.email.label')}
        requiredMessage={t('auth.resetPassword.emailIsRequired')}
        data-test="username-input"
      />
      <FormField
        name="code"
        type="text"
        autoComplete="one-time-code"
        inputMode="numeric"
        pattern="[0-9]*"
        label={t('auth.resetPassword.code.label')}
        requiredMessage={t('auth.resetPassword.codeIsRequired')}
        data-test="code-input"
      />
      <FormField
        name="password"
        type="password"
        autoComplete="new-password"
        label={t('auth.resetPassword.password.label')}
        requiredMessage={t('auth.resetPassword.passwordIsRequired')}
        data-test="password-input"
      />
      <PasswordValidation />
    </AuthPage>
  )
}
