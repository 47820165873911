import styled from 'styled-components'

interface OutlineBoxProps {
  active?: boolean
}

export const OutlineBox = styled.div<OutlineBoxProps>`
  border-radius: 10px;
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.colors.primary
        : props.theme.colors.transparentBlue};
  padding: 9px 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  transition: border 0.3s ease-in-out;
`
