import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.secondary};
  color: ${({ theme }) => theme.colors.blue};
  line-height: 20px;
  margin-bottom: 20px;
`

export interface Props {
  title: string
  children?: ReactNode
}

export function Section({ title, children, ...props }: Props): ReactElement {
  return (
    <div {...props}>
      <Title>{title}</Title>
      <div>{children}</div>
    </div>
  )
}
