import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'

type Opener = [boolean, () => void, () => void]

export function useOpener(url: string): Opener {
  const location = useLocation()
  const navigate = useNavigate()

  const [isOpen, toggle] = useToggle(false)
  const [path] = useState(location.pathname)

  const open = useCallback(() => {
    toggle(true)
    navigate(location.pathname + `/${url}`)
  }, [location.pathname, navigate, toggle, url])

  const close = useCallback(() => {
    toggle(false)
    navigate(path)
  }, [navigate, path, toggle])

  useEffect(() => {
    if (isOpen && location.pathname === path) {
      toggle(false)
      navigate(path)
      return
    }
  }, [isOpen, location.pathname, navigate, path, toggle, url])

  return [isOpen, open, close]
}
