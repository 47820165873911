import { useCallback } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useFlag } from '@unleash/proxy-client-react'
import styled from 'styled-components'
import { device } from 'src/device'
import { gql } from 'src/graphql'
import {
  withArray,
  withFragments,
  withUndefined,
} from 'src/libraries/graphql-fragments'
import { ScreenItem } from './ScreenItem'
import { ScreenItemDeprecated } from './ScreenItemDeprecated'
import { AddScreen } from './add-screen/AddScreen'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`

const ScreenItemWrapper = styled.div`
  margin-bottom: 30px;
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: ${100 / 3}%;

  @media ${device.tablet} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: 100%;
  }
`

const StyledAddScreen = styled(AddScreen)`
  min-height: 220px;
`

export const ScreenList = withFragments()(
  {
    screens: withUndefined(
      withArray(
        gql(/* GraphQL */ `
          fragment ScreenListFragment on Screen {
            id
            ...ScreenItemFragment
          }
        `)
      )
    ),
  },
  function ScreenList({ screens }) {
    const client = useApolloClient()
    const [addScreenMutate] = useMutation(
      gql(/* GraphQL */ `
        mutation addScreen($name: String!) {
          addScreen(name: $name) {
            id
            name
          }
        }
      `),
      {
        onCompleted: () => {
          client.refetchQueries({
            include: 'active',
          })
        },
      }
    )

    const addScreen = useCallback(
      async (name: string) =>
        addScreenMutate({
          variables: {
            name,
          },
        }),
      [addScreenMutate]
    )

    const editDisplayPage = useFlag('edit-display-page-240')

    return (
      <Container>
        {screens?.map((screen) => (
          <ScreenItemWrapper key={`screen-${screen.id}`}>
            {editDisplayPage ? (
              <ScreenItem screen={screen} />
            ) : (
              <ScreenItemDeprecated screen={screen} />
            )}
          </ScreenItemWrapper>
        ))}
        <ScreenItemWrapper key={`add-screen`}>
          <StyledAddScreen onAddScreen={addScreen} />
        </ScreenItemWrapper>
      </Container>
    )
  }
)
