import { PropsWithChildren } from 'react'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'src/elements/Button'
import { Media } from 'src/graphql/graphql'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ImageWrapper = styled.div`
  margin-bottom: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  border-radius: 10px;
  max-width: 70vw;
  max-width: calc(100vw - 60px);
  max-height: 70vh;
  object-fit: contain;
`

const ButtonWrapper = styled.div`
  align-self: flex-end;
  display: flex;
  margin: 0 -10px;
  flex-grow: 0;
  flex-shrink: 0;
`

const StyledButton = styled(Button)`
  margin: 0 10px;
`

interface Props {
  file: Partial<Pick<Media, 'url'>>
  onClose?: () => void
}

export function FilePreview({
  children,
  file,
  onClose,
  ...props
}: PropsWithChildren<Props>) {
  const { t } = useTranslation()
  const { url } = file

  return (
    <Wrapper {...props}>
      <ImageWrapper>{url !== undefined && <Image src={url} />}</ImageWrapper>
      <ButtonWrapper>
        <StyledButton
          secondary
          label={t('mediaLibrary.cancel')}
          onClick={() => onClose?.()}
        />
        {false && (
          <StyledButton icon={faTrashAlt} label={t('mediaLibrary.delete')} />
        )}
      </ButtonWrapper>
    </Wrapper>
  )
}
