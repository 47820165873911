import { ReactElement, useCallback, useState } from 'react'
import { faSortCircle } from '@fortawesome/pro-light-svg-icons'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { device } from 'src/device'
import { Box } from 'src/elements/Box'
import { Button } from 'src/elements/Button'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'

interface BoxProps {
  hasShadow: boolean
}

const StyledBox = styled(Box)<BoxProps>`
  height: 140px;
  box-shadow: ${(props) =>
    props.hasShadow
      ? '5px 5px 20px rgb(51, 46, 68, 0.5)'
      : '5px 5px 20px rgb(51, 46, 68, 0)'};
  transition: box-shadow 0.2s linear;
`

const Wrapper = styled.div`
  display: flex;
  margin: 0 -10px;
`

const ImageContainer = styled.div`
  height: 120px;
  width: calc(120px * 16 / 9);
  position: relative;
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.lightBlue};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Side = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  padding: 0 10px;
  flex-basis: 120px;
  align-items: center;
`

interface Collapsable {
  collapse: boolean
}

const LeftSide = styled(Side)<Collapsable>`
  opacity: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  ${(props) =>
    props.collapse
      ? 'padding: 0; opacity: 0; flex-grow: 0;  flex-basis: 0;'
      : ''};
`

const RightSide = styled(Side)<Collapsable>`
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  ${(props) => (props.collapse ? 'flex-basis: 0;' : '')};
`

const Center = styled.div<Collapsable>`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  opacity: 1;

  @media ${device.mobile} {
    ${(props) =>
      props.collapse ? 'padding: 0; opacity: 0; max-width: 0;' : ''};
  }
`

const IconWrapper = styled.div<Collapsable>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 120px;
  height: 100%;
  cursor: pointer;
  flex: 1;
  touch-action: none;
`

const SortIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 20px;
`

const DeleteIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
`

const OrderIconWrapper = styled(IconWrapper)<Collapsable>`
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.collapse
      ? 'opacity: 0; transform: translateX(-100%); pointer-events: none;'
      : 'opacity: 1; transform: translateX(0%); pointer-events: auto;'};
`

const DeleteIconWrapper = styled(IconWrapper)<Collapsable>`
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.collapse
      ? 'opacity: 0; transform: translateX(100%); pointer-events: none;'
      : 'opacity: 1; transform: translateX(0%); pointer-events: auto;'};
`

const DeleteWrapper = styled.div<Collapsable>`
  opacity: 0;
  transition: all 0.3s ease-in-out ${(props) => (props.collapse ? '0s' : '0s')};
  flex-shrink: 1;
  flex-basis: 0;
  flex-grow: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  ${(props) => (props.collapse ? 'pointer-events: auto; opacity: 1;' : '')};
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
`

const StyledButton = styled(Button)`
  margin: 0 10px;
`

interface Props {
  hasShadow?: boolean
  dragHandleProps?: Record<string, any>
  onDelete: () => void
  onImageClick: () => void
}

export const PlaylistItem = withFragments<Props>()(
  {
    media: gql(/* GraphQL */ `
      fragment MediaForPlaylistItem on Media {
        thumbnailUrl
      }
    `),
  },
  function PlaylistItem({
    media: { thumbnailUrl },
    hasShadow = false,
    dragHandleProps,
    onDelete,
    onImageClick,
    ...props
  }): ReactElement {
    const [isDeleteConfirmationShown, setDeleteConfirmationShown] =
      useState(false)

    const hideDeleteConfirmation = useCallback(() => {
      setDeleteConfirmationShown(false)
    }, [setDeleteConfirmationShown])

    const showDeleteConfirmation = useCallback(() => {
      setDeleteConfirmationShown(true)
    }, [setDeleteConfirmationShown])

    const { t } = useTranslation()

    return (
      <StyledBox hasShadow={hasShadow} smallPadding {...props}>
        <Wrapper>
          <LeftSide collapse={isDeleteConfirmationShown}>
            <OrderIconWrapper
              collapse={isDeleteConfirmationShown}
              data-test="drag-handle"
              {...dragHandleProps}
            >
              <SortIcon icon={faSortCircle} />
            </OrderIconWrapper>
          </LeftSide>
          <Center collapse={isDeleteConfirmationShown}>
            <ImageContainer>
              <ImageWrapper onClick={onImageClick}>
                <Image src={thumbnailUrl} />
              </ImageWrapper>
            </ImageContainer>
          </Center>
          <RightSide collapse={isDeleteConfirmationShown}>
            <DeleteWrapper collapse={isDeleteConfirmationShown}>
              <StyledButton
                label={t('playlists.edit.items.cancelDelete')}
                secondary
                onClick={hideDeleteConfirmation}
              />
              <StyledButton
                label={t('playlists.edit.items.delete')}
                onClick={onDelete}
              />
            </DeleteWrapper>
            <DeleteIconWrapper
              collapse={isDeleteConfirmationShown}
              onClick={showDeleteConfirmation}
              data-test="delete-icon"
            >
              <DeleteIcon icon={faTrashAlt} />
            </DeleteIconWrapper>
          </RightSide>
        </Wrapper>
      </StyledBox>
    )
  }
)
