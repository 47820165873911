import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ValidationProperty } from './ValidationProperty'
import { useValidatePassword } from './useValidatePassword'

const Container = styled.div`
  margin-bottom: 20px;
`

const Title = styled.p`
  color: ${(props) => props.theme.colors.blue};
  margin: 3px;
  font-size: 14px;
`

export function PasswordValidation() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'auth.resetPassword.validation',
  })
  const { watch } = useFormContext()
  const password = watch('password')
  const { length, upperChar, lowerChar, number } = useValidatePassword(password)

  return (
    <Container>
      <Title>{t('title')}</Title>
      <ValidationProperty property={length} name={t('length')} />
      <ValidationProperty property={upperChar} name={t('upperCase')} />
      <ValidationProperty property={lowerChar} name={t('lowerCase')} />
      <ValidationProperty property={number} name={t('number')} />
    </Container>
  )
}
