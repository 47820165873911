import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { Modal, ModalProps } from 'src/elements/Modal'

const StyledMotionDiv = styled(motion.div)`
  z-index: 1000;
  position: fixed;
`

export interface AnimatingModalProps extends ModalProps {
  show: boolean
  animatingKey?: string
}

export function AnimatingModal({
  show,
  animatingKey,
  children,
  ...props
}: AnimatingModalProps) {
  return (
    <AnimatePresence>
      {show && (
        <StyledMotionDiv
          key={animatingKey}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            ease: 'linear',
            duration: 0.25,
          }}
        >
          <Modal {...props}>{children}</Modal>
        </StyledMotionDiv>
      )}
    </AnimatePresence>
  )
}
