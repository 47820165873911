import { useCallback } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import styled from 'styled-components'
import { gql } from 'src/graphql'
import { withFragments } from 'src/libraries/graphql-fragments'
import { PlaylistItem } from './PlaylistItem'

const StyledPlaylistItem = styled(PlaylistItem)`
  margin-bottom: 15px;
`

interface WrapperProps {
  isActive: boolean
  transition: string | null
}

interface WrapperAttributes {
  transformX: number
  transformY: number
}

const Wrapper = styled.div.attrs<WrapperAttributes>(
  ({ transformX, transformY }) => ({
    style: {
      transform: `translate(
        ${transformX}px,
        ${transformY}px
      )`,
    },
  })
)<WrapperProps & WrapperAttributes>`
  position: relative;
  ${(props) => (props.transition ? `transition: ${props.transition}` : '')};
  z-index: ${(props) => (props.isActive ? 1000 : 0)};
  outline: none;
  user-select: none;
`

interface Props {
  id: string
  onDelete: (id: string) => void
  onSelectImage: (id: string) => void
}

export const SortableItem = withFragments<Props>()(
  {
    media: gql(/* GraphQL */ `
      fragment MediaForSortableItem on Media {
        ...MediaForPlaylistItem
      }
    `),
  },
  function SortableItem({ id, media, onDelete, onSelectImage }) {
    const { active, attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id })

    const isActive = active?.id === id

    const deleteItem = useCallback(() => {
      onDelete(id)
    }, [onDelete, id])

    const changeImageForItem = useCallback(() => {
      onSelectImage(id)
    }, [onSelectImage, id])

    return (
      <Wrapper
        data-test="item"
        ref={setNodeRef}
        isActive={isActive}
        transformX={transform?.x ?? 0}
        transformY={transform?.y ?? 0}
        transition={transition}
        {...attributes}
      >
        <StyledPlaylistItem
          hasShadow={isActive}
          media={media}
          dragHandleProps={listeners}
          onDelete={deleteItem}
          onImageClick={changeImageForItem}
        />
      </Wrapper>
    )
  }
)
