import { LoggedInRoutes } from 'src/LoggedInRoutes'
import { UnauthenticatedRoutes } from 'src/UnauthenticatedRoutes'
import { useAuthStore } from 'src/auth/useAuthStore'
import { Loader } from 'src/components/Loader'

export function Page() {
  const isLoggedIn = useAuthStore(({ isLoggedIn }) => isLoggedIn)

  if (isLoggedIn === null) {
    return <Loader />
  }

  if (isLoggedIn) {
    return <LoggedInRoutes />
  }

  return <UnauthenticatedRoutes />
}
